import { Alert, AlertProps } from 'antd';

import styles from './Alert.module.less';

const HVAlert = (props) => {
    return (
        <Alert
            {...props}
            className={styles['alert']}
            data-test-id={`feedback-alert-${props.type}`}
        />
    );
};

export default HVAlert;
