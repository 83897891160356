import { waitFor } from '@testing-library/react';
import { render } from '@testing-library/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom-6';


// A helper render function using TestRenderer to render a component within a router
// and routes to be which is needed for React Router 6 hooks
/**
 *
 * @param {Component} ui The component you want to render
 * @param {string} route the url location your page is mocked at
 */
export function renderWithRouter(ui, route = '/') {
    history.pushState({}, '', route);
    return {
        ...render(
            <BrowserRouter basename="/">
                <Routes>
                    <Route path={`${route}`} element={ui} />
                    <Route
                        path="*"
                        element={<div>Fake backup component</div>}
                    />
                </Routes>
            </BrowserRouter>
        )
    };
}

/**
 *
 * @param {string} dateStr The date you want to use as 'now'
 * @param {*} mockHolder
 */
export function mockNow(dateStr, mockHolder) {
    // Restore in between tests
    if (mockHolder) mockHolder.mockRestore();
    const mockDate = () => {
        return new Date(dateStr).getTime();
    };
    if (typeof jest !== 'undefined') {
        //doesn't work in-browser
        mockHolder = jest.spyOn(Date, 'now').mockImplementation(mockDate);
    }
}

/**
 *
 * @param {number} ms how long to wait in milliseconds
 * @param {function} [callback] what to do after the wait; optional
 */
export function wait(ms, callback = () => {}) {
    //call callback (if it exists), then resolve.
    return new Promise(r =>
        setTimeout(() => {
            callback();
            r();
        }, ms)
    );
}

// waitForValueToChange has not been included in the React Testing Library
// or React Native Testing Library APIs. A similar result can be achieved by
// implementing the the following, for a direct migration:
// helper function recommended in migration docs: https://github.com/testing-library/react-hooks-testing-library/blob/chore/migration-guide/MIGRATION_GUIDE.md#waitforvaluetochange
export const waitForValueToChange = async getValue => {
    const original = getValue();
    await waitFor(async () => {
        expect(await original).not.toBe(await getValue());
    });
};
