import moment from 'moment-timezone';

/**
 * global config for moment.calendar
 * Displays days as long form depending on their recency.
 */
export const calConfig = {
    lastDay: '[Yesterday] [at] h:mm A',
    sameDay: '[Today] [at] h:mm A',
    nextDay: '[Tomorrow] [at] h:mm A',
    lastWeek: 'dddd, M/D/YYYY, h:mm A',
    nextWeek: '[Next] dddd, M/D/YYYY, h:mm A',
    sameElse: 'M/D/YYYY, h:mm A'
};

/**
 * Returns whether input[type=date] is supported in the current browser
 * @date 6/30/21
 * @returns boolean
 */
export const isDateFieldSupported = function () {
    //if supported, date field will ignore non-number input
    let input = document.createElement('input');
    input.setAttribute('type', 'date');
    input.setAttribute('value', 'a');
    return input.value !== 'a';
};

/**
 * Acceptable date formats for HVDatePicker and HVDateRangePicker
 */
export const DATE_FORMATS = [
    'MM/DD/YYYY',
    'MM/D/YYYY',
    'M/DD/YYYY',
    'M/D/YYYY',
    'MM-DD-YYYY',
    'MM-D-YYYY',
    'M-DD-YYYY',
    'M-D-YYYY',
    'MMDDYYYY',
    'MDDYYYY',
    'MMDYYYY',
    'YYYY-MM-DD'
];

/**
 * @date 6/30/21
 * @returns boolean
 */
export const formatCal = date => moment(date).calendar(null, calConfig);

/**
 * TODO: Delete when old Messaging is deleted
 * @deprecated see about replacing with flexible date string
 * @param {string|Moment} subjectDate
 * @param {string|Moment} now
 * @returns {string} The time in h:mm A or h:mm A z format
 */
export function displayTime(subjectDate, now) {
    if (!(subjectDate instanceof moment)) subjectDate = momentTZ(subjectDate);
    if (!(now instanceof moment)) now = momentTZ(now);

    const sameZone = now.format('Z') == subjectDate.format('Z');
    return sameZone
        ? subjectDate.format('h:mm A')
        : subjectDate.format('h:mm A z');
}

/**
 * TODO: Delete when old Messaging is deleted
 * @deprecated Only used in displayTime function above
 * @param {string|Moment} subjectDate
 * @param {string|Moment} now
 * @returns {string} The time in h:mm A or h:mm A z format
 */
export function momentTZ(timeObject) {
    if (!timeObject) return moment.tz(moment.tz.guess());
    if (!timeObject.timezone) {
        return moment.tz(timeObject.time, moment.ISO_8601, moment.tz.guess());
    }
    return moment.tz(timeObject.time, moment.ISO_8601, timeObject.timezone);
}

/**
 * @deprecated use day-utils instead
 * @param {string|Moment} value Date to be formatted, either in string or Moment object format.
 * @param {string} timezone String representing the timezone
 * @returns {Moment} A moment object based on the date
 */
export function flexibleDate(value, timezone) {
    if (!value) return null;
    let date = null;
    if (value === 'custom') return moment; //if we really need moment itself
    if (value === 'now') date = moment();
    if (value instanceof moment) date = value;
    if (value instanceof Date) date = moment(value);

    if (typeof value === 'string' || value instanceof String) {
        // is a string
        if (value.includes('/')) date = moment(value, 'MM/DD/YYYY');
        if (value.includes('-')) {
            date = moment(value, moment.ISO_8601);
            if (!date.isValid()) {
                //Not ISO, last hope is MM-DD-YYYY :/
                date = moment(value, 'MM-DD-YYYY');
            }
        }
    }
    if (!isNaN(parseFloat(value)) && isFinite(value)) {
        // is a number
        date = moment(value, 'x');
    }

    if (!date) {
        console.error(`Invalid date passed to flexibleDate: ${value}`);
        return null;
    }

    if (timezone) {
        date = date.tz(timezone);
    }
    return date;
}

/**
 * @deprecated use day-utils instead
 * @param {string|Moment} value
 * @param {string} [timezone] String representing the timezone
 * @param {string} [format] A date format. Defaults to MES.
 * Supported formats are:
 * 1. 'ISO' - ISO-8601: 2021-02-25
 * 2. 'MES' - Middle-endian slash: 02/25/2021
 * 3. 'DATE-WORDS' - Written out: Saturday, February 13th 2019
 * 4. 'DAY-TIME-WORDS' - Shorter version of above: February 13th, 3:15 P
 * 5. 'MES-TIME-TZ' - MES with time and timezone: 02/25/2021 3:15 PM CST
 * 6. 'ISO-NOON' - ISO with time hardcoded to noon: 2021-02-25T12:00
 * 7. 'ISO-MIDNIGHT' - ISO with time hardcoded to midnight: 2021-02-25T00:00
 * 8. 'TIME-TZ' - Only time (no date) and timezone: 3:15 PM CST
 * @returns {string} The date formatted correctly per the provided format.
 */
export function flexibleDateString(value, timezone, format) {
    let date = flexibleDate(value, timezone);
    if (!date) return null;
    if (timezone) date = date.tz(timezone);
    if (format === 'ISO') return date.format('YYYY-MM-DD');
    if (!format || format === 'MES') return date.format('MM/DD/YYYY');
    if (format === 'DATE-WORDS') return date.format('dddd, MMMM Do YYYY');
    if (format === 'DAY-TIME-WORDS') return date.format('MMMM Do, h:mm A z');
    if (format === 'MES-TIME-TZ') return date.format('MM/DD/YYYY h:mm A z');
    if (format === 'ISO-NOON') return date.format('YYYY-MM-DDT12:00');
    if (format === 'ISO-MIDNIGHT') return date.format('YYYY-MM-DDT00:00Z');
    if (format === 'TIME-TZ') return date.format('h:mm A z');

    console.error(`flexibleDateString: Invalid date format "${format}"`);
    return null;
}
