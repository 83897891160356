import { isValidNumber } from 'libphonenumber-js';
import { castArray as forceArray, isPlainObject as isObject } from 'lodash';
import moment from 'moment-timezone';

import { wait } from './utils';
import { formatDisplayPhone } from '../Validation/Normalizer';

import { HVSentry } from '$Utils';

/**
 * Formats a field in object format into an easily readable string.
 * @param {object} fieldProps Properties of a field that you want to display.
 * @returns string
 */
export const fieldDisplayValue = (fieldProps, language = 'en-US') => {
    let { choices: rawChoices, fieldExtras, value } = fieldProps;

    if (value == null) return '';
    let displayValue = value || '';

    // if value is a boolean, make it more readable
    if (value === true) displayValue = language === 'es' ? 'Sí' : 'Yes';
    if (value === false) displayValue = 'No';
    const isString = typeof value === 'string' || value instanceof String;
    if (isString && isValidNumber(value)) {
        return formatDisplayPhone(value);
    }

    if (rawChoices) {
        // If empty string, exit out of this process
        if (value === '') return;

        // Multi-choice fields will already be an array;
        // Convert all others so we can process the same way.
        if (!Array.isArray(value)) {
            value = [value];
        }
        let finalChoices = [];
        if (Array.isArray(rawChoices)) {
            // Go through the value array
            if (language === 'en-US') {
                finalChoices = rawChoices.map(choice => {
                    // For arrays, this is a passthrough
                    // They are in the correct format already
                    if (Array.isArray(choice)) return choice;

                    // For choices where format is
                    //  { display_name: blah, value: blah}
                    // we need to convert to the standard format (array)
                    if (isObject(choice) && choice.display_name) {
                        return [choice.value, choice.display_name];
                    }
                    //If the formatting is weird, report it.
                    const choiceFormatError = `Choice ${choice} for the field ${
                        fieldProps.label || fieldProps.name
                    } is neither an object or array; see fieldDisplayValue in utils.js`;

                    console.error(choiceFormatError);

                    HVSentry.sendExceptionToReactSentry(choiceFormatError);
                    return ['', ''];
                });

                //Now that everything is formatted correctly
                // determine the display value
                displayValue = value
                    .map(value => {
                        const correctChoice = finalChoices.find(choice => {
                            // Find the choice array whose raw value matches
                            // the current raw value.
                            return choice[0].toString() === value.toString();
                        });
                        // And then get the second item in
                        // that choice array, AKA the display value
                        if (correctChoice) return correctChoice[1];

                        //If we can't find the match, report it
                        const choiceMismatchError = `Choice mismatch, no correct choice could be found for the field ${
                            fieldProps.label || fieldProps.name
                        }. Choices: ${JSON.stringify(
                            finalChoices
                        )} | Value: ${value}. See fieldDisplayValue in utils.js`;

                        console.error(choiceMismatchError);
                        HVSentry.sendExceptionToReactSentry(
                            choiceMismatchError
                        );

                        return value;
                        // Join all the display values together with
                        // a comma and a space.
                    })
                    .join(', ');
            }

            if (language === 'es' && fieldExtras?.choices_es) {
                const {
                    choices_es: { value: esValueChoiceList }
                } = fieldExtras;
                esValueChoiceList.forEach(esValueChoicePair => {
                    const checkValues = fieldValue => {
                        // compare choice value to actual (selected) field value
                        if (
                            esValueChoicePair.split('|')[0] ===
                            fieldValue.toString()
                        ) {
                            // push the display value if equal
                            finalChoices.push(esValueChoicePair.split('|')[1]);
                        }
                    };
                    // check each value in value(s) array
                    value.forEach(fieldValue => checkValues(fieldValue));
                });
                displayValue = finalChoices.join(', ');
            }
        }
    }

    // If the value is a valid date, format it properly
    if (moment(value, moment.ISO_8601).isValid() && value.length > 4) {
        displayValue = moment(value, moment.ISO_8601).format('MM/DD/YYYY');
    }

    return displayValue;
};

export function fieldExtraMatch(fieldExtra, formValues) {
    if (!fieldExtra || !formValues) return false;
    //make sure all inputs are arrays
    const ruleFields = forceArray(fieldExtra.field);
    const ruleValues = forceArray(fieldExtra.value);

    let subjectValues = [];
    ruleFields.forEach(fieldName => {
        // Values can be arrays as well, make them always be arrays
        // Also, force everything in the array to be a string
        // to avoid type mismatches
        const fieldValue = forceArray(formValues[fieldName]).map(val =>
            val ? val.toString() : ''
        );
        // make a copy
        subjectValues = subjectValues.concat(fieldValue);
    });

    //check to see if ANY rule values match ANY subject values.
    const match = ruleValues.some(val => {
        // Force to string on both sides to avoid type mismatches
        return subjectValues.includes(val.toString());
    });
    return match;
}

/**
 * A helper that captures the position of the cursor / caret before a user types in an input, and restores it once they are done typing. Useful in custom form input transforms.
 * @param {object} target An instance of event.target
 */
export async function adjustCaretAfterEdit(target) {
    const { selectionStart, value } = target;

    if (selectionStart != value.length) {
        await wait(1);
        target.selectionStart = target.selectionEnd = selectionStart;
        return null;
    }
}

/**
 * Checks the strength of a given password with zxcvbn. This should probably live with the other validations functions.
 * @param {string} value
 * @returns number
 */
export const passwordStrength = value => {
    if (!value) return 0;
    return import(/* webpackChunkName: "zxcvbn" */ 'zxcvbn').then(
        ({ default: zxcvbn }) => {
            return zxcvbn(value).score;
        },
        error => {
            HVSentry.sendExceptionToReactSentry(error);
        }
    );
};
