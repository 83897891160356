/* eslint-disable no-self-assign */
import 'react-app-polyfill/stable';
import * as Sentry from '@sentry/react';

import 'autotrack/lib/plugins/url-change-tracker';
import 'autotrack/lib/plugins/media-query-tracker';
import 'autotrack/lib/plugins/outbound-link-tracker';

import '$Styles/global.less';

// An auto executing function included by webpack that gives us everything we need for boilerplate, including GA & app tagging for Sentry

(function () {
    // Polyfill fix - see https://github.com/Financial-Times/polyfill-library/issues/163
    Object.prototype[Symbol.toStringTag] = Object.prototype[Symbol.toStringTag];
    if (!!window.use_sentry) {
        Sentry.init({
            dsn: window.jsDSN,
            integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
            environment: window.sentry_environment,
            release: window.sentry_release,
            ignoreErrors: [
                // Random plugins/extensions
                'top.GLOBALS',
                'originalCreateNotification',
                'canvas.contentDocument',
                'MyApp_RemoveAllHighlights',
                'http://tt.epicplay.com',
                'jigsaw is not defined',
                'ComboSearch is not defined',
                'atomicFindClose',
                // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
                // reduce this. (thanks @acdha)
                // See http://stackoverflow.com/questions/4113268
                'bmi_SafeAddOnload',
                'EBCallBackMessageReceived',
                // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
                'conduitPage',
                'Network Error'
            ],
            denyUrls: [
                /extensions\//i,
                /^chrome:\/\//i,
                /^file:\/\//i,
                /^pptr:\/\//i
            ],
            tracesSampleRate: 0.2,
            replaysOnErrorSampleRate: 1.0
        });
    }
    // TODO: Make sure the correct FF populates
    // setSentryTag('feature-family', process.env.FEATURE_FAMILY);
})();
