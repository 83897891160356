import * as Sentry from '@sentry/react';
export const HVSentry = {
    sendExceptionToReactSentry: (error, options) => {
        if (window.use_sentry) {
            return Sentry.captureException(error, options);
        }
    },
    sendMessageToReactSentry: (message, options) => {
        if (window.use_sentry) {
            return Sentry.captureMessage(message, options);
        }
    },
    // Passing the _browserClient in here for testing purposes,
    // but this might also allow us to keep the same context for the Django Sentry project
    sendMessageToDjangoSentry: (metadata, _browserClient) => {
        const genericError = 'Handled JS Error: No metadata returned with response.';
        if (window.use_sentry) {
            let django;
            if (!_browserClient) {
                django = new Sentry.BrowserClient({
                    dsn: window.djDSN,
                    environment: window.sentry_environment,
                    release: window.sentry_release,
                    transport: Sentry.makeFetchTransport,
                    integrations: [],
                    stackParser: Sentry.defaultStackParser
                });
            }
            else {
                django = _browserClient;
            }
            if (metadata) {
                django.captureMessage(`Handled JS Error: ${metadata.url || 'A url'} returned a${metadata.status ? ' ' + metadata.status : 'n error'} with either no response, or unparseable JSON: ${metadata.response}`);
            }
            else {
                django.captureMessage(genericError);
            }
        }
    }
};
