import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js';
import { deburr, kebabCase } from 'lodash';

import { flexibleDate } from '../Utils/date-utils';

/**
 * Some field types need to be normalized before submission, including dates.
 * @param {Object} options
 * @param {Array} options.fields Array of field metadata objects that includes their type
 * @param {Object} options.values Key/value pairs of field values from the current form.
 * @returns {Object} Normalized values ready for submission to the server.
 */

export function normalizeSubmissionValues({ fields, values: _values } = {}) {
    if (!_values) return null;
    if (!fields) return _values;
    let values = JSON.parse(JSON.stringify(_values));

    let fieldsObject = {};
    fields.forEach(field => (fieldsObject[field.name] = field));

    Object.keys(values).forEach(fieldName => {
        let value = values[fieldName];
        const field = fieldsObject[fieldName];
        if (field && field.type === 'date') {
            values[fieldName] = flexibleDate(value).format('YYYY-MM-DD');
        }
    });
    return values;
}

/**
 * @date 2021-03-10
 * @param {string} value
 * @returns {string}
 */
export const normalizeDateBasic = value => {
    if (!value) return '';
    let onlyNums = value.replace(/[^\d]/g, '');
    //appending "/" if it exists allows user to optionally type/delete the slash
    let finalCharacter = value.slice(-1) === '/' ? '/' : '';
    if (!onlyNums.length) return '';

    let month = onlyNums.slice(0, 2);
    //if larger than 12, assume patient didn't include zero pad
    if (month > 12) {
        onlyNums = `${0}${onlyNums}`;
        month = onlyNums.slice(0, 2);
    }
    if (onlyNums.length <= 2) {
        return `${month}${finalCharacter}`;
    }
    let day = onlyNums.slice(2, 4);
    //if larger than 31, assume patient didn't include zero pad
    if (day > 31) {
        onlyNums = `${month}0${onlyNums.slice(2, 8)}`;
        day = onlyNums.slice(2, 4);
    }
    if (onlyNums.length <= 4) {
        return `${month}/${day}${finalCharacter}`;
    }
    const year = onlyNums.slice(4, 8);
    return `${month}/${day}/${year}`;
};

/**
 * @date 2021-03-10
 * @param {string} value
 * @returns {string}
 */
export const normalizeIntlDate = value => {
    if (!value) return '';
    let onlyNums = value.replace(/[^\d]/g, '');
    if (!onlyNums.length) return '';
    // appending "/" if it exists allows user to optionally type/delete the slash
    let finalCharacter = value.slice(-1) === '/' ? '/' : '';
    // don't actually do any assumptions on what format, just add delimiters
    let month = onlyNums.slice(0, 2);
    if (onlyNums.length <= 2) {
        return `${month}${finalCharacter}`;
    }
    let day = onlyNums.slice(2, 4);
    if (onlyNums.length <= 4) {
        return `${month}/${day}${finalCharacter}`;
    }
    const year = onlyNums.slice(4, 8);
    return `${month}/${day}/${year}`;
};

/**
 * Takes a string and returns only numbers in that string.
 * @param {string} value
 * @returns {string}
 */
export const numbers = value => {
    if (!value) return '';
    return value.replace(/[^\d]/g, '');
};

/**
 * Removes dashes, parens, letters, and other non-valid characters from a phone number, and by default appends a `+` and the country code and appropriate spacing.
 *
 *
 * @date 2021-03-10
 * @param {string} value The initial phone number value
 * @param {object} [options] Additional options.
 * @param {boolean} [options.format=true] Whether to apply libphonenumber's `formatInternational` method.
 * @param {boolean} [options.spaces=true] Whether or not to remove any spaces from a formatted phone number
 * @returns {string} Formatted phone number
 */

export const normalizePhone = (value, options) => {
    // Default to formatting to intl format with spaces e.g. +1 414 555 5555
    // NOTE: Spaces really only makes a difference if `format` === true
    const settings = { format: true, spaces: true, ...options };
    const { format, spaces } = settings;

    if (!value) return '';
    let numbers = value.replace(/[^\d]/g, '');
    if (!numbers.length) return '';

    const phoneNumber = parsePhoneNumberFromString(numbers, 'US');
    const returnNumbersOnly =
        !phoneNumber || !phoneNumber?.isValid() || !format;
    if (returnNumbersOnly) return numbers;
    if (spaces) return phoneNumber.formatInternational();
    const noSpaces = phoneNumber.formatInternational().replace(/\s/g, '');
    return noSpaces;
};

/**
 * @date 2021-03-10
 * @param {string} value
 * @returns {string}
 */
export const formatPhone = value => {
    if (!value) return '';
    let numbers = value.replace(/[^\d+]/g, '');
    if (!numbers.length) return '';
    if (numbers[0] === '1') numbers = numbers.substr(1);
    //default to US, but will be overridden by manual country codes.
    return new AsYouType('US').input(numbers);
};

/**
 * @date 2021-03-10
 * @param {string} value
 * @returns {string}
 */
export const formatDisplayPhone = value => {
    if (!value) return '';
    let onlyNums = value.replace(/[^\d]/g, '');
    if (!onlyNums.length) {
        if (value[0] === '+') return '+';
        return '';
    }

    //if number starts with "+1", this is a US number, remove the "+1"
    if (onlyNums[0] === '1') {
        onlyNums = onlyNums.slice(1);
    } else if (value[0] === '+') {
        //If "+" (but not "+1"), don't attempt to format
        return value;
    }

    //If more than 10 numbers, don't attempt to format
    if (onlyNums.length > 10) {
        return value;
    }

    if (onlyNums.length <= 3) {
        return `(${onlyNums}`;
    }
    if (onlyNums.length <= 7) {
        return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
    }
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(
        6,
        10
    )}`;
};

/**
 * Adds dashes to a social security number.
 * @date 2021-03-10
 * @param {string} value
 * @returns {string} Formatted social security number.
 */
export const normalizeSocialSecurity = value => {
    if (!value) return '';
    let finalValue = value.replace(/[^\d]/g, '');
    //add hyphens in reverse order so we don't have indexes move on us
    if (finalValue.length > 5) {
        finalValue = finalValue.slice(0, 5) + '-' + finalValue.slice(5);
    }
    if (finalValue.length > 3) {
        finalValue = finalValue.slice(0, 3) + '-' + finalValue.slice(3);
    }
    return finalValue;
};

/**
 * per the CSS spec (https://www.w3.org/TR/CSS21/syndata.html#value-def-identifier)
 * identifiers must meet the following:
 * * only [a-zA-Z0-9-_]
 * * certain unicode band allowed (ISO 10646 >= U+0A00)
 * * cannot start with a digit, two hyphens, or a hyphen followed by a digit
 * you _can_ escape characters, however, it's easier to strip them
 * out and not bother.
 * reformatting them allows for an easier time for AT and automation to find
 * them in the page.
 * this does not, however, handle UUID-based names well.
 * @date 2021-03-10
 * @param {string} value
 * @returns {string}
 */
export const normalizeCSSID = value => {
    const newID = kebabCase(deburr(value).replace(/[^a-zA-Z0-9-_]/, '-'));

    if (newID.match(/^[0-9-]/)) {
        // invalid starting bits. slice off what works and return it
        const chunks = newID.split('-');
        let safeIndex = 0;
        for (let i = 0; i < chunks.length; i++) {
            // dashes were the delimiter so they don't exist here
            if (chunks[i].match(/^[^0-9]/)) {
                safeIndex = i;
                break;
            }
        }
        return chunks.slice(safeIndex).join('-');
    }

    return newID;
};
